<template>
  <div>
    <div v-if="!admin">
      <div class="container pt-5">
        <h2>VOCÊ NÃO TEM ACESSO A ESTA PÁGINA</h2>
      </div>
    </div>
    <div v-else>
      <div class="container page-section">
        <b-card title="Editar Estabelecimento">
          <ValidationObserver ref="validate">
            <b-form
              @submit.prevent="onSubmit"
              @reset.prevent="cancel"
              v-if="show"
            >
              <div class="row">
                <b-form-group
                  id="input-group-1"
                  label="Identificador do estabelecimento"
                  label-for="input-1"
                  class="col-md-6 col-sm-12"
                >
                  <b-form-input disabled="" v-model="form.id"></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Data do cadastro"
                  class="col-md-6 col-sm-12"
                >
                  <b-form-input
                    disabled=""
                    v-model="form.dataCadastro"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Nome do estabelecimento"
                  label-for="input-1"
                  class="col-md-6 col-sm-12"
                >
                  <ValidationProvider
                    name="nome"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.nome"
                      placeholder="Estabelecimento"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <validation-provider
                  name="CNPJ"
                  rules="cnpj"
                  v-slot="validationContext"
                  class="col-md-6 col-sm-12"
                >
                  <b-form-group
                    id="cnpj-input-group"
                    label="CNPJ"
                    label-for="cnpj-input"
                    :invalid-feedback="validationContext.errors[0]"
                    :state="getValidationState(validationContext)"
                    description="(Opcional)"
                  >
                    <b-form-input
                      id="cnpj-input"
                      name="cnp-input"
                      v-model="form.cnpj"
                      v-mask="['##.###.###/####-##']"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  id="input-group-8"
                  label="Categoria do estabelecimento "
                  label-for="input-8"
                  class="col-md-12 col-sm-12"
                >
                  <ValidationProvider
                    name="tipo do estabelecimento"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      select-label="Pressione enter para selecionar"
                      deselect-label="Pressione enter para remover"
                      selected-label
                      v-model="form.tiposEstabelecimento"
                      :options="tipos"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Selecione um ou mais tipos"
                      label="nome"
                      track-by="id"
                      :preselect-first="true"
                    ></multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- INICIO NOVO ENDERECO -->

                <!-- CEP -->
                <!-- <b-form-group label="CEP" class="col-md-3 col-sm-12">
                  <ValidationProvider
                    name="cep"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.cep"
                      placeholder="00000-000"
                      v-mask="'#####-###'"
                      @change="buscaCep(form.enderecoObj.cep)"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group> -->

                <!-- ESTADO -->
                <b-form-group
                  id="input-group-7"
                  label="Estado "
                  label-for="input-7"
                  class="col-md-3 col-sm-12"
                >
                  <ValidationProvider
                    name="Estado"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      select-label="Selecionar"
                      deselect-label="Remover"
                      selected-label="Selecionado"
                      v-model="form.estado"
                      :options="estados"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      placeholder="Estado"
                      label="name"
                      disabled
                    ></multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- CIDADE -->
                <b-form-group
                  id="input-group-7"
                  label="Cidade "
                  label-for="input-7"
                  class="col-md-3 col-sm-12"
                >
                  <ValidationProvider
                    name="Cidade"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      select-label="Selecionar"
                      deselect-label="Remover"
                      selected-label="Selecionado"
                      v-model="form.cidadeObj"
                      :options="cidades"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      placeholder="Cidade"
                      label="nome"
                      disabled
                    ></multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- BAIRRO -->
                <b-form-group label="Bairro" class="col-md-3 col-sm-12">
                  <ValidationProvider
                    name="bairro"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.bairro"
                      placeholder="Bairro"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- RUA -->
                <b-form-group label="Rua" class="col-md-4 col-sm-12">
                  <ValidationProvider
                    name="rua"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.rua"
                      placeholder="Rua"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- NUMERO -->
                <b-form-group label="Número" class="col-md-2 col-sm-12">
                  <ValidationProvider
                    name="numero"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.numero"
                      placeholder="00a"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- REFERENCIA -->
                <b-form-group label="Complemento" class="col-md-6 col-sm-12">
                  <ValidationProvider
                    name="referencia"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.enderecoObj.referencia"
                      placeholder="Complemento"
                    >
                    </b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <!-- FIM NOVO ENDERECO -->

                <b-form-group
                  label="Dias de funcionamento "
                  class="col-md-6 col-sm-12"
                >
                  <ValidationProvider
                    name="dias"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-checkbox-group
                      v-model="form.diasFuncionamento"
                      id="checkboxes-4"
                      :options="dias"
                      text-field="label"
                      value-field="value"
                    ></b-form-checkbox-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  id="input-group-9"
                  label="Horários de funcionamento "
                  label-for="input-9"
                  class="col-md-6 col-sm-12"
                >
                  <ValidationProvider
                    name="horarios"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-textarea
                      id="input-9"
                      v-model="form.horarioFuncionamento"
                      placeholder="Seg - Sex: 07:00 às 20:00
  Sab - Dom: 08:00 às 18:00 "
                      rows="3"
                      max-rows="6"
                      style="max-height: 100px; min-height: 50px; overflow-y: hidden"
                    ></b-form-textarea>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  id="input-group-11"
                  label="Realiza delivery? "
                  label-for="input-11"
                  class="col-md-3 col-sm-6"
                >
                  <ValidationProvider
                    name="retirada"
                    rules="required"
                    v-slot="{ errors }"
                    style="padding-right: 15px;"
                  >
                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="form.isDelivery"
                      :options="confirmOptions"
                      buttons
                      button-variant="outline-success"
                      size="md"
                      name="radio-btn-outline"
                      class="mb-2"
                    ></b-form-radio-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <money
                    class="col-md-6"
                    v-show="form.isDelivery"
                    v-model="form.valorDelivery"
                    placeholder="Valor delivery"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-12"
                  label="Permite retirada? "
                  label-for="btn-radios-1"
                  class="col-md-3 col-sm-6"
                >
                  <ValidationProvider
                    name="retirada"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="form.isPermiteRetirada"
                      :options="confirmOptions"
                      buttons
                      button-variant="outline-success"
                      size="md"
                      name="radio-btn-outline"
                    ></b-form-radio-group>
                    <br />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  id="input-group-12"
                  label="Tempo médio de espera (minutos)"
                  label-for="input-12"
                  class="col-md-6 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    id="input-12"
                    v-model="form.tempoEspera"
                    type="number"
                    v-mask="'###'"
                    placeholder="Tempo médio de espera"
                    :disabled="!form.isDelivery"
                  ></b-form-input>
                </b-form-group>

                <!-- Fone -->
                <b-form-group
                  id="input-group-4"
                  label="Telefone(s) para contato "
                  label-for="input-4"
                  class="col-12 col-md-6 col-sm-12"
                >
                  <b-row
                    class="d-flex justify-content-between"
                    v-for="(item, index) in form.telefones"
                    :key="index"
                  >
                    <b-col cols="12" class="mb-3 d-flex">
                      <ValidationProvider
                        class="flex-grow-1"
                        :name="`Telefone ${index + 1}`"
                        rules="required|min:11"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          :id="`telefone-${index + 1}`"
                          v-model="form.telefones[index].numero"
                          v-mask="['(##) ####-####', '(##) #####-####']"
                          placeholder="(99) 99999-9999"
                        ></b-form-input>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div
                        class="d-flex justify-content-center align-items-center ml-1"
                        style="background: #e9ecef; padding: 5px; border: 0.5px solid #ced4da; border-radius: 0.25rem; height: 40px;"
                      >
                        <b-form-checkbox
                          v-model="form.telefones[index].whatsapp"
                          class="ml-2 align-self-start"
                          switch
                        >
                          <unicon
                            name="whatsapp"
                            :fill="
                              form.telefones[index].whatsapp
                                ? 'green'
                                : '#6c757d66'
                            "
                          />
                        </b-form-checkbox>
                      </div>
                      <b-button
                        class="ml-1"
                        variant="outline-secondary"
                        @click="removerTelefone(index)"
                        v-show="!(index === 0 && form.telefones.length === 1)"
                        style="height: 40px;"
                      >
                        <b-icon-trash />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-button
                    variant="success"
                    block
                    @click="adicionarTelefone"
                    v-show="form.telefones.length < 3"
                  >
                    Adicionar Telefone
                  </b-button>
                </b-form-group>
                <!-- end fone -->

                <b-form-group
                  id="input-group-16"
                  label="Email "
                  label-for="input-16"
                  class="col-md-6 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    id="input-16"
                    v-model="form.email"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-13"
                  label="Instagram "
                  label-for="input-13"
                  class="col-md-6 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    id="input-13"
                    v-model="form.instagram"
                    placeholder="@usuario"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-14"
                  label="Facebook "
                  label-for="input-14"
                  class="col-md-6 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    id="input-14"
                    v-model="form.facebook"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-15"
                  label="Site "
                  label-for="input-15"
                  class="col-md-6 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    id="input-15"
                    v-model="form.site"
                    placeholder=""
                  ></b-form-input>
                </b-form-group>

                <!--
                <b-form-group
                  id="input-group-16"
                  label="Ifood "
                  label-for="input-16"
                  class="col-md-6 col-sm-12"
                  description="(opcional)"
                >
                  <b-form-input
                    id="input-16"
                    v-model="form.ifood"
                    placeholder=""
                  ></b-form-input>
                </b-form-group>
                -->

                <b-form-group
                  id="input-group-9"
                  label="Descrição "
                  label-for="input-9"
                  class="col-md-12 col-sm-12"
                  :description="
                    `Restam ${300 - form.descricao.length} caracteres`
                  "
                >
                  <ValidationProvider
                    name="descricao"
                    :rules="showDescription ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <b-form-textarea
                      id="input-9"
                      v-model="form.descricao"
                      placeholder="Descreva seu negócio ou adicione palavras chaves para o cliente encontrar seu negócio
  Ex: água, chaveiro, frutas, mecânico..."
                      max-rows="3"
                      maxlength="300"
                      style="max-height: 150px; min-height: 100px; overflow-y: hidden"
                    ></b-form-textarea>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  id="input-group-14"
                  label="Recomendação"
                  label-for="input-14"
                  class="col-md-12 col-sm-12 check-termos"
                >
                  <p class="termos">
                    Comerciantes, antes de cadastrar, busque informações sobre
                    as medidas de prevenção e controle recomendadas pelas
                    autoridades sanitárias, especialmente as relacionadas à
                    pandemia do coronavírus COVID 19. Não esqueça a saúde do
                    trabalhador, inclusive nas empresas familiares, e no
                    transporte entrega das mercadorias, conforme recomendações
                    do Ministério Público do Trabalho no
                    <a
                      href="https://mpt.mp.br/pgt/noticias/vrecomendacao-estado-de-sp-conafret-assinada-pdf-pdf.pdf"
                      target="_blank"
                      >link</a
                    >.
                  </p>
                  <b-form-checkbox
                    v-model="form.aceitaRecomendacoes"
                    name="check-button"
                    switch
                    variant="success"
                  >
                    De acordo com as recomendações do Ministério Público do
                    Trabalho.
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div class="d-flex bd-highlight col">
                <div class="flex-grow-1 bd-highlight"></div>
                <b-row>
                  <b-col>
                    <div class="pb-3">
                      <b-button
                        type="submit"
                        variant="success"
                        @click="save()"
                        style="width: 100%; min-width: 160px;"
                        >Salvar Edição</b-button
                      >
                    </div>
                  </b-col>
                  <b-col>
                    <div class="pb-3">
                      <b-button
                        type="reset"
                        variant="secondary"
                        style="width: 100%; min-width: 160px;"
                        >Cancelar Edição</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </ValidationObserver>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Service from "../services/ApiServices";
import { localize } from "vee-validate";

localize({
  pt_BR: {
    fields: {
      descricao: {
        required:
          "Ao selecionar o tipo do estabelecimento como 'outros' é necessário uma descrição"
      }
    }
  }
});

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      admin: false,
      showDescription: false,
      form: {
        id: "",
        dataCadastro: "",
        descricao: "",
        aceitaRecomendacoes: false,
        horarioFuncionamento: "",
        isAprovada: true,
        cnpj: "",
        nome: "",
        endereco: "",
        telefones: [
          {
            numero: "",
            whatsapp: false
          }
        ],
        enderecoObj: {
          // cep: "",
          bairro: "",
          rua: "",
          numero: "",
          referencia: ""
        },
        instagram: "",
        facebook: "",
        email: "",
        site: "",
        //ifood: "",
        estado: undefined,
        cidade: undefined,
        cidadeObj: undefined,
        valorDelivery: "",
        tempoEspera: "",
        tiposEstabelecimento: undefined,
        diasFuncionamento: [],
        isDelivery: true,
        isPermiteRetirada: undefined
      },
      confirmOptions: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      tipos: [],
      estados: [],
      cidades: [],
      dias: [
        { label: "Domingo", value: "DOMINGO" },
        { label: "Segunda-feira", value: "SEGUNDA_FEIRA" },
        { label: "Terça-feira", value: "TERCA_FEIRA" },
        { label: "Quarta-feira", value: "QUARTA_FEIRA" },
        { label: "Quinta-feira", value: "QUINTA_FEIRA" },
        { label: "Sexta-feira", value: "SEXTA_FEIRA" },
        { label: "Sábado", value: "SABADO" }
      ],
      show: true
    };
  },

  beforeCreate() {
    const request = new Service();
    if (this.$route.params.id) {
      request.getById(this.$route.params.id, "empresas/findById").then(res => {
        if (res != null) {
          this.form.telefones = res.telefones ? res.telefones : [];
          this.form.cnpj = res.cnpj ? res.cnpj : "";
          this.form.id = res.id;
          this.form.dataCadastro = res.dataCadastro ? res.dataCadastro : "";
          this.form.descricao = res.descricao ? res.descricao : "";
          this.form.aceitaRecomendacoes = res.aceitaRecomendacoes
            ? res.aceitaRecomendacoes
            : "";
          this.form.horarioFuncionamento = res.horarioFuncionamento
            ? res.horarioFuncionamento
            : "";
          this.form.isAprovada = res.isAprovada ? res.isAprovada : "";
          this.form.nome = res.nome ? res.nome : "";
          this.form.endereco = res.endereco ? res.endereco : "";
          this.form.instagram = res.instagram ? res.instagram : "";
          this.form.facebook = res.facebook ? res.facebook : "";
          this.form.email = res.email ? res.email : "";
          this.form.site = res.site ? res.site : "";
          //this.form.ifood = res.ifood ? res.ifood : "";
          this.form.estado = res.estado ? res.estado : "";

          this.form.cidadeObj = res.cidadeObj ? res.cidadeObj : "";
          // this.form.enderecoObj.cep = res.enderecoObj ? res.enderecoObj.cep : "";
          this.form.enderecoObj.bairro = res.enderecoObj
            ? res.enderecoObj.bairro
            : "";
          this.form.enderecoObj.rua = res.enderecoObj
            ? res.enderecoObj.rua
            : "";
          this.form.enderecoObj.numero = res.enderecoObj
            ? res.enderecoObj.numero
            : "";
          this.form.enderecoObj.referencia = res.enderecoObj
            ? res.enderecoObj.referencia
            : "";

          this.form.valorDelivery = res.valorDelivery ? res.valorDelivery : "";
          this.form.tempoEspera = res.tempoEspera ? res.tempoEspera : "";
          this.form.tiposEstabelecimento = res.tiposEstabelecimento
            ? res.tiposEstabelecimento
            : "";
          this.form.diasFuncionamento = res.diasFuncionamento
            ? res.diasFuncionamento
            : "";
          this.form.isDelivery = res.isDelivery != null ? res.isDelivery : "";
          this.form.isPermiteRetirada =
            res.isPermiteRetirada != null ? res.isPermiteRetirada : "";
          // this.form.isAprovada =
          //   res.isAprovada != null ? res.isAprovada : true;
        }
      });
    } else this.$router.push({ name: "Developers" });
  },

  created() {
    if (localStorage.adminnastro && localStorage.adminnastro == "true")
      this.admin = localStorage.adminnastro;
  },

  watch: {
    "form.tiposEstabelecimento"() {
      try {
        const isValid = this.form.tiposEstabelecimento.filter(
          e => e.nome === "Outros"
        )[1];

        this.showDescription = isValid ? true : false;
      } catch (error) {
        this.showDescription = false;
      }
    },
    "form.isPermiteRetirada"(value) {
      if (!value) {
        this.form.isDelivery = true;
      }
    },
    "form.isDelivery"(value) {
      if (!value) {
        this.form.valorDelivery = 0;
        this.form.isPermiteRetirada = true;
      }
    }
  },

  mounted() {
    const request = new Service();
    request
      .getAll({ sort: "nome", size: 50 }, "tiposEstabelecimento")
      .then(res => (this.tipos = res.content));
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    isOthers() {},

    removerTelefone(index) {
      this.form.telefones.splice(index, 1);
    },
    adicionarTelefone() {
      if (this.form.telefones.length <= 2)
        this.form.telefones.push({ numero: "", whatsapp: false });
    },
    async save() {
      const valid = await this.$refs.validate.validate();
      if (!valid) {
        this.$notify({
          group: "toast",
          type: "warn",
          title: "Cadastro",
          text: "Preencha os campos obrigatórios"
        });
        return;
      }
      let obj = Object.assign({}, this.form);
      obj.cidade = obj.cidadeObj.nome;
      const request = new Service();
      const response = await request.update(obj, obj.id, "empresas");
      if (response) {
        this.$notify({
          group: "toast",
          title: "Cadastro",
          text: "Cadastro Atualizado com sucesso!"
        });
        this.$router.push("/Developers");
      }
    },

    cancel() {
      this.$router.push({ path: "/developers/Estabelecimentos" });
    },
    onSubmit() {},

    async buscaCep(cep = "") {
      if (cep !== "") {
        const request = new Service();
        const resultBuscaCep = await request.buscaViaCep(cep);
        if (!resultBuscaCep.erro) {
          this.form.enderecoObj.referencia = resultBuscaCep.complemento;
          this.form.enderecoObj.rua = resultBuscaCep.logradouro;
          this.form.enderecoObj.bairro =
            resultBuscaCep.bairro === "" ? "Centro" : resultBuscaCep.bairro;

          if (resultBuscaCep.uf != this.form.estado.uf) {
            const newEstado = await this.getEstadoByUf(resultBuscaCep.uf);
            if (newEstado && newEstado != null) this.form.estado = newEstado;
            const newCidade = await this.getCidadeByUfAndName(
              resultBuscaCep.uf,
              resultBuscaCep.localidade
            );
            if (newCidade && newCidade != null) this.form.cidadeObj = newCidade;
          } else {
            const newCidade = await this.getCidadeByUfAndName(
              resultBuscaCep.uf,
              resultBuscaCep.localidade
            );
            if (newCidade && newCidade != null) this.form.cidadeObj = newCidade;
          }
        }
      }
    },

    async getCidadeByUfAndName(uf = "", nome = "") {
      const request = new Service();
      return await request.getAll(
        { uf: uf, nome: nome },
        "cidades/findByNomeAndEstado"
      );
    },

    async getEstadoByUf(uf = "") {
      const request = new Service();
      return await request.getAll({ uf: uf }, "estados/findByUf");
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.page-section {
  padding: 3rem 0;
}
.check-termos {
  padding: 15px;
}
.termos {
  font-size: 13px;
  padding: 5px;
}
</style>
